// Form will go here 
import React from 'react';
import Layout from '../commons/layout';
import axios from 'axios';
import _ from 'lodash';
import Select from 'react-select';
import countries from '../commons/countries';
// For uploader
import Dropzone from 'react-dropzone';
import { treatments, main_colors, sub_colors, parcel_options, gems_cut, Shape } from '../commons/common_vars';


class UpdateInventory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            subcategories: [],
            token: localStorage.getItem('userToken'),
            isCatLoaded: false,
            subcategoriesLoaded: false,
            attributes: [],
            attributes_input: [],
            new_attributes: [],
            uploadedImages: [],
            uploadedVideos: [],
            // States related to image uploading
            images: [],
            imagesInQueue: [],
            videosInQueue: [],
            lot_visibility: true,
            measurement: "",
            total_cost: 0,
            total_price: 0
        }
        this.getCategories();
        this.getInventory();
        this.onDrop = (files) => {
            // console.log(files);
            this.setState({ files })
        };
        console.log(this.props);
    }

    getInventoryLotID = async () => {
        try {
            let uri = window.location.pathname.split('/').pop();
            // let data = ""
            console.log(uri);
            this.setState({
                lotId: uri,
            }, () => {
                console.log(this.state);
                this.getInventory()
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    getInventory = async () => {
        let id = window.location.hash.split('/').pop();
        console.log(id);
        try {

            let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/inventory/lot/${id}`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data;
            console.log(data);
            this.getSubcategoryOnLoad(data.categoryId);

    
                let newAttr = [];
                data.attributes.map((item) => {
                    newAttr[item.attribute_label] = item.attribute_value;
                    return 0;
                })
                let MEAS1 = (newAttr["MEAS1"]) ? newAttr["MEAS1"] : 0;
                let MEAS2 = (newAttr["MEAS2"]) ? newAttr["MEAS2"] : 0;
                let MEAS3 = (newAttr["MEAS3"]) ? newAttr["MEAS3"] : 0;

                let measure = "";
                if (MEAS1 !== 0 || MEAS2 !== 0 || MEAS3 !== 0) {
                    measure = MEAS1 + "x" + MEAS2 + "x" + MEAS3;
                }

                let _total_cost = (data.cost_price * ((data.count)?data.count: 1));
                let _total_price = (data.sell_price * ((data.count)?data.count: 1));
    
            this.setState({
                _id: data._id,
                category: data.categoryId[0],
                subcategory: data.subcategoryId[0],
                // category: data.categoryId[0],
                name: data.name,
                description: data.description,
                measure_unit: data.measure_unit,
                cost: data.cost_price,
                price: data.sell_price,
                attributes: data.attributes,
                new_attributes: newAttr,
                weight: data.weight,
                count: data.count,
                lot_visibility: data.lot_visibility,
                uploadedImages: data.images,
                uploadedVideos: data.videos,
                measurement: measure,
                total_cost: _total_cost,
                total_price: _total_price
            })

            // this.setState(data)
        }
        catch (e) {
            console.log(e);
        }
    }

    getSubcategoryOnLoad = async (ct) => {
        let catId = ct;
        console.log("sub" + catId)
        if (catId === -1) {
            this.setState({
                category: catId,
                subcategories: []
            });
            return;
        }
        try {
            this.state.selectCat = catId;
            let response = await axios.get(`https://caratpal.thesecondwheel.in/api/subcategory/${catId}`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data;
            this.setState({
                category: catId,
                subcategories: data,
                subcategoriesLoaded: true,
            }, () => { this.getAttributesByCategory() });

        } catch (e) {
            console.log(e);
            alert("Cannot load subcategories due to an issue")
        }
    }
    /**
     * This will collect all the inputs 
     * @param {*} event 
     */
    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        await this.setState({
            [name]: value
        });
        console.log(this.state);
        if (event.target.name === 'subcategory' || event.target.name === 'category') {
            this.getAttributesByCategory()
        }else if(name==="price" || name==="count" || name==="cost"){
            let _total_cost = (this.state.cost * ((this.state.count)?this.state.count: 1));
            let _total_price = (this.state.price * ((this.state.count)?this.state.count: 1));
            this.setState({
                total_cost: _total_cost,
                total_price: _total_price,
            })
        }
    }

    getCategories = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data;
            // console.log(data);
            this.setState({
                categories: data,
                subcategoriesLoaded: false,
                subcategories: [],
                isCatLoaded: true,
            })
        }
        catch (e) {
            // console.log(e);
        }
    }

    getSubcategory = async (e) => {
        let catId = e.target.value;
        if (catId === -1) {
            this.setState({
                category: catId,
                subcategories: []
            });
            return;
        }
        try {
            let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/subcategory/${catId}`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data;
            this.setState({
                category: catId,
                subcategories: data,
                subcategoriesLoaded: true,
            }, () => {
                // console.log(this.state);
                this.getAttributesByCategory()
            });

        } catch (e) {
            // // console.log(e);
            alert("Cannot load subcategories due to an issue")
        }
    }
    getAttributesByCategory = async () => {
        // this.sendData()
        let subcategoryAttribute = [];
        let categoryAttributes = _.find(this.state.categories, { _id: this.state.category });
        if (this.state.subcategory) {
            subcategoryAttribute = _.find(this.state.subcategories, { _id: this.state.subcategory })
        }

        if (categoryAttributes && subcategoryAttribute) {
            let arr = _.merge(categoryAttributes.attributes, subcategoryAttribute.attributes);
            let newAttr = [];
            arr.map((item) => {
                newAttr[item.name] = "";
                return 0;
            })
            console.log(newAttr);
            this.setState({
                attributes: arr,
                new_attributes: newAttr
            });
        }
    }

    sendData = async () => {
        let att_number_label = ['Avg Weight', 'PPC', 'MEAS1', 'MEAS2', 'MEAS3'];
        this.state.attributes_input = this.state.attributes.map((item) => {
            let attr = item;
            if (att_number_label.includes(item.attribute_label)) {
                attr.attribute_value = Number(item.attribute_value)
            }
            return attr
        });

        if(!this.state.new_attributes["Avg Weight"]){
            this.state.new_attributes.push({"Avg Weight": (this.state.weight / this.state.count)})
            this.state.attributes.push(
                {
                    attribute_label: "Avg Weight",
                    attribute_value: (this.state.weight / this.state.count)
                }
            );
        }

        console.log(this.state);

        let ready_to_send_data = {
            name: this.state.name,
            categoryId: this.state.category[0],
            subcategoryId: this.state.subcategory,
            cost_price: this.state.cost,
            price: this.state.price,
            sell_price: this.state.price,
            weight: this.state.weight,
            count: this.state.count,
            date_acquired: new Date().toJSON().slice(0, 10),
            attributes: this.state.attributes,
            description: this.state.description,
            measure_unit: this.state.measure_unit,
            lot_visibility: this.state.lot_visibility,
            images: this.state.uploadedImages,
            videos: this.state.uploadedVideos
        }
        let id = window.location.hash.split('/').pop();
        console.log(id);
        // let user = localStorage.getItem('userId');
        // let dataSent = await axios.put('http://localhost:8100/api/inventory/625006a5bfcfe0d0797afd88', ready_to_send_data)
        console.log(ready_to_send_data)
        let dataSent = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_BASE_URL}/inventory/${id}`,
            data: ready_to_send_data,
            headers: { "Authorization": `Bearer ${this.state.token}` },
        });
        if (dataSent) {
            console.log(dataSent);
            alert("Lot updated");
        }
    }

    /**remove images from inventory object */

    removeImage = async (e) => {
        // console.log(e.target.attributes.getNamedItem('data-index').value);
        let index = e.target.attributes.getNamedItem('data-index').value;
        let images = this.state.uploadedImages;
        images.splice(index, 1);
        console.log(images);
        this.setState({
            uploadedImages: images
        });
    }

    removeVideo= async(e) =>{
        console.log(e.target.attributes.getNamedItem('data-index').value);
        let index = e.target.attributes.getNamedItem('data-index').value;
        let videos = this.state.uploadedVideos;
        videos.splice(index, 1);
        console.log(videos);
        this.setState({
            uploadedVideos: videos
        });
    }

    handleAttrChange = async (event) => {
        console.log(event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let arrayCopy = [...this.state.attributes];
        let newAttr = this.state.new_attributes;
        newAttr[name] = value;
        let itemCopy = {
            attribute_label: name,
            attribute_value: value
        };
        let i = 0;
        for (i = 0; i < arrayCopy.length; i++) {
            if (name === arrayCopy[i].attribute_label) {
                break;
            }
        }
        arrayCopy[i] = itemCopy;
        console.log(newAttr);
        let MEAS1 = (this.state.new_attributes["MEAS1"]) ? this.state.new_attributes["MEAS1"] : 0;
        let MEAS2 = (this.state.new_attributes["MEAS2"]) ? this.state.new_attributes["MEAS2"] : 0;
        let MEAS3 = (this.state.new_attributes["MEAS3"]) ? this.state.new_attributes["MEAS3"] : 0;

        let measure = "";
        if (MEAS1 !== 0 || MEAS2 !== 0 || MEAS3 !== 0) {
            measure = MEAS1 + "x" + MEAS2 + "x" + MEAS3;
        }
        this.setState({
            attributes: [...arrayCopy],
            new_attributes: newAttr,
            measurement: measure
        })
        console.log(itemCopy);
    }

    handleAttrSelectChange = async (event) => {
        console.log(event);
        const target = event;
        const value = target.value;
        const name = target.name;
        let arrayCopy = [...this.state.attributes];
        let newAttr = this.state.new_attributes;
        newAttr[name] = value;
        let itemCopy = {
            attribute_label: name,
            attribute_value: value
        };
        let i = 0;
        for (i = 0; i < arrayCopy.length; i++) {
            if (name === arrayCopy[i].attribute_label) {
                break;
            }
        }
        arrayCopy[i] = itemCopy;
        console.log(newAttr);

        this.setState({
            attributes: [...arrayCopy],
            new_attributes: newAttr,
            // measurement: measure
        })
        // console.log(itemCopy);
    }

    // Image Uploader
    dropzoneHandleNewFiles = async (files) => {
        if (!files.length) {
            alert('No files selected');
            return;
        }
        // console.log(files);
        let isVideo = files[0].path.includes(".mp4") | files[0].path.includes(".mp3") | files[0].path.includes(".webm");
        // Show as b64
        let reader = new FileReader();
        reader.onload = (e) => {
            // console.log(e.target.result);
            if (isVideo) {
                let newVideo = {
                    src: e.target.result,
                };
                this.setState({
                    videosInQueue: [
                        ...this.state.videosInQueue,
                        newVideo
                    ],
                }, async () => {
                    this.uploadFiles(files, isVideo);
                })
            }
            else {
                let newImage = {
                    src: e.target.result,
                };
                this.setState({
                    imagesInQueue: [
                        ...this.state.imagesInQueue,
                        newImage
                    ],
                }, async () => {
                    this.uploadFiles(files, isVideo);
                })
            }
        };
        reader.readAsDataURL(files[0]);
    }

    uploadFiles = async (files, isVideo) => {
        try {
            let newFormData = new FormData();
            newFormData.append('files', files[0]);
            let result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/file/upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${this.state.token}`
                },
                data: newFormData
            });

            if (result.data.status === "Success") {
                let uploaded_files = result.data.data;
                if (isVideo) {
                    uploaded_files.map((video) => {
                        this.setState({
                            uploadedVideos: [...this.state.uploadedVideos, video.location]
                        })
                        return 0;
                    });
                }
                else {
                    uploaded_files.map((img) => {
                        this.setState({
                            uploadedImages: [...this.state.uploadedImages, img.location]
                        })
                        return 0;
                    });
                }
            }
        }
        catch (e) {
            console.error(e);
        }

    }

    render() {
        var select = ["Parcel Type", "Shape", "Cut", "Clarity"];
        
        var option = [
            ["Single", "Pair", "Lot", "Set"],
            ["Oval", "Pear", "Cushion", "Rectangular", "Trilliant", "Heart", "Marquise", "Round", "Square", "Fancy"],
            ["Fine Step-cut", "Modified Brilliant-cut", "Emerald-cut", "Radiant-cut", "Scissor-cut", "Brilliant Diamond-cut", "Asscher-cut", "Princess-cut", "Cabochon", "Rose-cut", "Checkered-cut", "Thai-cut", "Diamond-cut"],
            ["IF", "VVS", "VS", "S-1", "SI-2", "I", "O"]
        ];
        return <div>
            <Layout />
            <main>

                {/* General Details  */}
                <div className="card w-100 pb-2">
                    <div className="card-body">
                        <h5 className="card-title"> General </h5>

                        <div className="row g-3">
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" type="text" name="lot_id" className="form-control" value={this.state._id} disabled />
                                    <label htmlFor="name" className="form-label"> Lot Id   </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <div className="form-floating mb-3">
                                        <select className="form-select" name="category" onChange={this.getSubcategory} value={(this.state.category)? this.state.category: -1} id="floatingSelect" aria-label="State">
                                            <option key={-1} value={-1}> Select </option>
                                            {this.state.categories.map(item =>
                                                <option key={item._id} value={item._id}> {item.name} </option>
                                            )}
                                        </select>
                                        <label htmlFor="floatingSelect">Lot Type </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input onChange={this.handleInputChange} placeholder="Select " type="text" value={(this.state.name)? this.state.name : ""} name="name" className="form-control" />
                                    <label htmlFor="name" className="form-label"> Lot Title   </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <div className="form-floating mb-3">
                                        <select className="form-select" name="subcategory" onChange={this.handleInputChange} value={(this.state.subcategory)? this.state.subcategory : -1} id="floatingSelect" aria-label="State">
                                            <option key="-1" value={-1}> Select </option>

                                            {(this.state.subcategoriesLoaded) ? this.state.subcategories.map((item) =>
                                                <option key={item._id} value={item._id}> {item.name} </option>
                                            ) : ''}

                                        </select>
                                        <label htmlFor="floatingSelect">Category</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select " type="number" min='0' value={(this.state.count)? this.state.count: 0} name="count" className="form-control" />
                                    <label htmlFor="count" className="form-label">Quantity  </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Enter weight" type="number" id="weight" value={(this.state.weight)? this.state.weight: "" } name='weight' className="form-control" />
                                    <label htmlFor="weight" className="form-label"> Weight </label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <Select placeholder={"Select Parcel type"} className='form-control' value={ (this.state.new_attributes["Parcel Type"])? { value:this.state.new_attributes["Parcel Type"], label: this.state.new_attributes["Parcel Type"]}:""} onChange={this.handleAttrSelectChange} name="Parcel Type" options={parcel_options}/>
                                    {/* <select onChange={this.handleAttrChange} type="text" name="Parcel Type" className="select2 form-select">
                                        <option value="" key="-1">{'Select Parcel Type'}</option>
                                        {option[select.indexOf('Parcel Type')].map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select> */}
                                    <label htmlFor="weight" className="form-label"> Parcel Type </label>
                                </div>
                            </div>

                            <div className="col-3" key="901">
                                <div className='form-floating mb-3'>
                                    <input placeholder="Select" readOnly value={parseFloat(this.state.weight / this.state.count).toFixed(2)} type="text" name="Avg Weight" className="form-control" />
                                    <label htmlFor="Avg Size" className="form-label"> Avg Weight </label>
                                </div>
                            </div>

                            {/* unlink */}
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <input className="form-control" placeholder="Address" name="trade_name" />
                                    <label htmlFor="description">Trade Name </label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className='form-floating'>
                                    <div className="form-floating mb-3">
                                        <select className="form-select" name="lot_visibility" onChange={this.handleInputChange} value={this.state.lot_visibility} id="lotTypesSelect" aria-label="State">
                                            <option key="0" value={true} > Public </option>
                                            <option key='1' value={false} > Private </option>
                                        </select>
                                        <label htmlFor="floatingSelect">Visibility</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/* Definition Details  */}
                <div className="card w-100 pb-2">
                    <div className="card-body">
                        <h5 className="card-title"> Definition </h5>

                        <div className="row g-3">
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleAttrChange} type="text" name="Gem Type" className="form-control" value={this.state.new_attributes["Gem Type"]} />
                                    <label htmlFor="name" className="form-label"> Gem Type </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                <Select className='form-control' onChange={this.handleAttrSelectChange} 
                                value={ (this.state.new_attributes["Origin Country"])?{ value:this.state.new_attributes["Origin Country"], label: this.state.new_attributes["Origin Country"]}:""} 
                                name="Origin Country" options={countries}/>
                                    {/* <select onChange={this.handleAttrChange} type="text" value={this.state.new_attributes['Origin Country']} name="Origin Country" className="form-select">
                                        <option value="" key="-1">{'Select Origin'}</option>
                                        {countries.map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select> */}
                                    {/* <input placeholder="-" onChange={this.handleAttrChange} type="text" name="Origin Country" className="form-control" value={this.state.new_attributes["Origin Country"]} /> */}
                                    <label htmlFor="name" className="form-label"> Origin Country </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <Select className='form-control' onChange={this.handleAttrSelectChange} value={(this.state.new_attributes["Cut"])? { value:this.state.new_attributes["Cut"], label: this.state.new_attributes["Cut"]}:""} name="Cut" options={gems_cut}/>
                                    {/* <select onChange={this.handleAttrChange} type="text" name="Cut" className="form-select">
                                        <option value="" key="-1">{'Select Gemstone Cut'}</option>
                                        {option[select.indexOf('Cut')].map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select> */}
                                    <label htmlFor="weight" className="form-label"> Gemstone Cut </label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <Select className='form-control' onChange={this.handleAttrSelectChange}
                                    value={(this.state.new_attributes["Shape"])? { value:this.state.new_attributes["Shape"], label: this.state.new_attributes["Shape"]}:""}
                                    name="Shape" options={Shape}/>
                                    {/* <select onChange={this.handleAttrChange} type="text" name="Shape" className="form-select">
                                        <option value="" key="-1">{'Select Shape'}</option>
                                        {option[select.indexOf('Shape')].map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select> */}
                                    <label htmlFor="weight" className="form-label"> Shape </label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleAttrChange} type="number" name="MEAS1" className="form-control" value={this.state.new_attributes["MEAS1"]} />
                                    <label htmlFor="name" className="form-label"> MEAS1 </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleAttrChange} type="number" name="MEAS2" className="form-control" value={this.state.new_attributes["MEAS2"]} />
                                    <label htmlFor="name" className="form-label"> MEAS2 </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleAttrChange} type="number" name="MEAS3" className="form-control" value={this.state.new_attributes["MEAS3"]} />
                                    <label htmlFor="name" className="form-label"> MEAS3 </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleInputChange} type="text" name="measurement" className="form-control" value={this.state.measurement} />
                                    <label htmlFor="name" className="form-label"> Measurements </label>
                                </div>
                            </div>
                        </div>

                        <div className="row g-3 mt-3">
                            
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <Select className='form-control' onChange={this.handleAttrSelectChange}
                                    value={ (this.state.new_attributes["Treatment"])? { value:this.state.new_attributes["Treatment"], label: this.state.new_attributes["Treatment"]}:""}
                                    name="Treatment" options={treatments}/>
                                    {/* <select onChange={this.handleAttrChange} type="text" name="Treatment" className="form-select">
                                        <option value="" key="-1">{'Select Treatment'}</option>
                                        {treatments.map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select> */}
                                    <label htmlFor="weight" className="form-label"> Treatment </label>
                                </div>
                            </div>
                            
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    {/* <Select className='form-control' onChange={this.handleAttrChange} name="Cut" options={parcel_options}/> */}
                                    <select onChange={this.handleAttrChange} type="text" name="Colour1" value={this.state.new_attributes['Colour1']} className="form-select">
                                        <option value="" key="-1">{'Select Colour1'}</option>
                                        {main_colors.map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select>
                                    <label htmlFor="weight" className="form-label"> Colour1 </label>
                                </div>
                            </div>
                            
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    {/* <Select className='form-control' onChange={this.handleAttrChange} name="Cut" options={parcel_options}/> */}
                                    <select onChange={this.handleAttrChange} type="text" value={this.state.new_attributes['Colour2']} name="Colour2" className="form-select">
                                        <option value="" key="-1">{'Select Colour2'}</option>
                                        {(this.state.new_attributes.indexOf("Colour1"))?
                                            sub_colors[this.state.new_attributes["Colour1"]]?.map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )
                                        :
                                        ""}
                                    </select>
                                    <label htmlFor="weight" className="form-label"> Colour2 </label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className='form-floating'>
                                    <input placeholder="-" onChange={this.handleAttrChange} type="text" value={this.state.new_attributes["Colour Code"]} name="Colour Code" className="form-control" />
                                    <label htmlFor="name" className="form-label"> Colour Code </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    {/* <Select className='form-control' onChange={this.handleAttrChange} name="Cut" options={parcel_options}/> */}
                                    <select onChange={this.handleAttrChange} type="text" name="Clarity" value={this.state.new_attributes["Clarity"]} className="form-select">
                                        <option value="" key="-1">{'Select Clarity'}</option>
                                        {option[select.indexOf('Clarity')].map((item, index) =>
                                            <option value={item} key={index}>{item}</option>
                                        )}
                                    </select>
                                    <label htmlFor="weight" className="form-label"> Clarity </label>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

                {/* Cost Details  */}
                <div className="card w-100 pb-2">
                    <div className="card-body">
                        <h5 className="card-title"> Pricing </h5>

                        <div className="row g-3">
                            <div className="col-6">
                                <div className='p-3'> </div>
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select " type="number" value={this.state.cost} name="cost" className="form-control" />
                                    <label htmlFor="cost" className="form-label"> Cost Price  </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='pb-2'>Total</div>
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select" type="number" name='total_cost' value={this.state.total_cost} className="form-control" />
                                    <label htmlFor="price" className="form-label"> As per Cost   </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select" type="number" name='price' value={this.state.price} className="form-control" />
                                    <label htmlFor="price" className="form-label"> Asking Price   </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select" type="number" name='total_price' value={this.state.total_price} className="form-control" />
                                    <label htmlFor="price" className="form-label"> As per Asking Price   </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Cost Details  */}
                <div className="card w-100 pb-2">
                    <div className="card-body">
                        <h5 className="card-title"> Certificates </h5>

                        <div className="row g-3">
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select " type="text" name="lab_account_id" className="form-control" />
                                    <label htmlFor="cost" className="form-label"> Lab Account Id  </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} placeholder="Select" type="text" name='certificate' className="form-control" />
                                    <label htmlFor="price" className="form-label"> Certificate   </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-floating mb-3'>
                                    <input onChange={this.handleInputChange} type="date" max={new Date().toISOString().substr(0, 10)}  name='certificate_date' className="form-control" />
                                    <label htmlFor="price" className="form-label"> Certificate Date   </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='card w-100 pb-2'>
                    <div className='card-body'>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Dropzone onDrop={files => this.dropzoneHandleNewFiles(files)} maxSize={9999999999}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className="image-uploader-zone">
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files (Image/Video support)</p>
                                        </div>
                                    )}
                                </Dropzone>

                                <div className='images-uploading'>
                                    {
                                        this.state.uploadedImages.map((img, idx) =>
                                            <div className='image-upload-preview' key={idx} >
                                                <img src={img} alt="lotimage" />
                                                <span><span className="btn btn-sm delete-image text-danger" title="remove image"  ><i className="bi bi-x" onClick={ this.removeImage } data-index= {idx}></i></span></span>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className='images-uploading'>
                                    {
                                        this.state.uploadedVideos.map((vid, idx) =>
                                            <div className='image-upload-preview' key={idx} >
                                                <video src={vid} />
                                                {/* <span> Video</span> */}
                                                <span><span className="btn btn-sm delete-image text-danger" title="remove image"  ><i className="bi bi-x" onClick={ this.removeVideo } data-index= {idx}></i></span></span>
                                            </div>
                                        )
                                    }
                                </div>



                            </div >
                            <div className='col-md-6'>
                                <div className="images-uploaded">

                                </div>
                            </div>
                        </div >

                    </div >
                </div >

                <div className="my-4 d-flex justify-content-center">
                    <button onClick={this.sendData} className="mr-2 btn btn-primary"> Update Lot </button>
                    {/* <button type="reset" className="btn btn-secondary">Reset</button> */}
                </div>
            </main >
        </div >
    }
}

export default UpdateInventory; 