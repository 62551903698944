import React from 'react';
import navigationHook from '../commons/hooks';
import { NavLink } from 'react-router-dom';

class Layout extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    logout = async () => {
        localStorage.clear();
        this.props.navigate('/login');
    }

    render() {
        return <div>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">CaratPal</span>
                    </a>
                    {/* <i className="bi bi-list toggle-sidebar-btn"></i> */}
                </div>

                {/* <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                </form>
            </div> */}

                <nav className="header-nav ms-auto">
                    <ul className="d-none d-flex align-items-center">


                        <li className="nav-item dropdown pe-3">

                            <a href="/" className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                                {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                <span className="d-none d-md-block dropdown-toggle ps-2">K. Anderson</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>Kevin Anderson</h6>
                                    <span>Web Designer</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-gear"></i>
                                        <span>Account Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i className="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="/">
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>

            <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <NavLink to="/add" className={isActive =>  "nav-link" + (isActive.isActive?  "": " collapsed") }>
                            <i className="bi bi-plus"></i>
                            <span>Add Inventory </span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/inventory" className={isActive =>  "nav-link" + (isActive.isActive ?  "": " collapsed") }>
                            <i className="bi bi-eye"></i>
                            <span>View Inventory </span>
                        </NavLink>
                    </li>
                    <li className="nav-item" onClick={this.logout}>
                        <span role="button" className="cursor-pointer nav-link collapsed">
                            <i className="bi bi-box-arrow-right"></i>
                            <span>Logout </span>
                        </span>
                    </li>

                </ul>

            </aside>
        </div>
    }
}

export default navigationHook(Layout);  