// import logo from './logo.svg';
// import 'remixicon/fonts/remixicon.css'
import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/style.css';
import AddInventory from './pages/inventory-add';
import Login from './pages/login';
import ViewInventory from './pages/inventory-list';
import UpdateInventory from './pages/inventory-update';

function App() {
  
  return (
    <div className='App'>
      <HashRouter basename=''>
        <Routes>
          <Route path='/' element={<Login />}> </Route>
          <Route path='/login' element={<Login />}> </Route>
          <Route path='/add' element={<AddInventory />}> </Route>
          <Route path='/inventory' element={<ViewInventory ></ViewInventory>}></Route>
          <Route path='/inventory/:id' element={<UpdateInventory></UpdateInventory>}></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
