const main_colors = [
    "Black", "Grey", "White", "Tan", "Yellow", "Orange",
    "Red", "Pink", "Purple", "Green", "Blue", "Brown"
];
const sub_colors = {
    "Black": ["Black", "Metal", "Charcoal"],
    "Grey": ["Metallic", "ASH", "Grey", "Silver"],
    "White": ["Linen", "Cream", "Ivory", "Coconut", "Frost", "White"],
    "Tan": ["Hazelnut", "Sepia", "Latte", "Hazelwod", "Beige", "Buttermilk", "Tan"],
    "Yellow": ["Golden", "Cron", "Canary", "Honey", "Pineapple", "Yellow", "Lemon", "Daffodil", "Banana"],
    "Orange": ["Clay", "Ginger", "Fire", "Orange", "Apricot", "Mandarin", "Fanta", "Marigold", "Cantaloupe"],
    "Red": ["Blood", "Scarlet", "Cherry", "Crimson", "Red", "Vivid Red", "Rose", "Blush"],
    "Pink": ["Rosewood", "Strawberry", "Vivid Pink", "Fuscia", "Bubblegum", "Watermelon", "Coral", "Peach",
        "Salmon", "Flower", "Flamingo", "Pink", "Cotton", "Baby Pink"
    ],
    "Purple": ["Eggplant", "Mulberry", "Grape", "Mauve", "Magenta", "Violet", "Purple", "Lilac", "Orchid", "Lavender"],
    "Green": ["Basil", "Moss", "Forest", "Vivid Green", "Emerald", "Green", "Neon", "Olive", "Lime", "Pistachio",
        "Pale", "Seafoam", "Cotton", "Mist"],
    "Blue": ["Denim", "Indigo", "Navy", "Azure", "Lapis", "Cobalt", "Blue", "Ocean", "Teal", "Turquoise",
        "Mint", "Ice", "Arctic", "Baby Blue", "Sky"],
    "Brown": ["Brown", "Chocolate", "Cinnamon", "Wood", "Coffee", "Peanut"]
};

const treatments = [
    { label: "-", value: "-", name: "Treatment" },
    { label: "N - Not Enhanced", value: "N - Not Enhanced", name: "Treatment" },
    { label: "B - Bleaching", value: "B - Bleaching", name: "Treatment" },
    { label: "C - Coating", value: "C - Coating", name: "Treatment" },
    { label: "D - Dyeing", value: "D - Dyeing", name: "Treatment" },
    { label: "F - Filling", value: "F - Filling", name: "Treatment" },
    { label: "H - Heating", value: "H - Heating", name: "Treatment" },
    { label: "HF - Heat & Filling", value: "HF - Heat & Filling", name: "Treatment" },
    { label: "HP - Heat & Pressure", value: "HP - Heat & Pressure", name: "Treatment" },
    { label: "I - Impregnation", value: "I - Impregnation", name: "Treatment" },
    { label: "L - Lasering", value: "L - Lasering", name: "Treatment" },
    { label: "OR - Oiling & Resin", value: "OR - Oiling & Resin", name: "Treatment" },
    { label: "O1 - Insignificant Oil", value: "O1 - Insignificant Oil", name: "Treatment" },
    { label: "O2 - Minor Oil", value: "O2 - Minor Oil", name: "Treatment" },
    { label: "O3 - Minor to Moderate Oil", value: "O3 - Minor to Moderate Oil", name: "Treatment" },
    { label: "04 - Moderate Oil", value: "04 - Moderate Oil", name: "Treatment" },
    { label: "R - Irradiation", value: "R - Irradiation", name: "Treatment" },
    { label: "U - Diffusion", value: "U - Diffusion", name: "Treatment" },
    { label: "W - Waxing/Oil on Opaque", value: "W - Waxing/Oil on Opaque", name: "Treatment" },
    { label: "SC - Special Care Required", value: "SC - Special Care Required", name: "Treatment" },
    { label: "ASBL - Assembled", value: "ASBL - Assembled", name: "Treatment" },
    { label: "CMP - Composite", value: "CMP - Composite", name: "Treatment" },
    { label: "HB - Heat with Beryllium", value: "HB - Heat with Beryllium", name: "Treatment" },
    { label: "CE - Clarity Enhanced", value: "CE - Clarity Enhanced", name: "Treatment" },
];

// const treatments = [
//     "-",
//     "No Indication of heat treatment",
//     "Indication of heat treatment",
//     "Treatment Undeterminable",
//     "Heated and/or Irradiated",
//     "Minor Oil",
//     "No Oil",
//     "Insignificant Oil",
//     "Moderate Oil",
//     "Enhanced by heat - residues insignificant",
//     "Clarity Enhanced",
//     "Enhanced by pressure and high temperature",
//     "Enhanced by heat and light elements (Such as beryllium)",
//     "Industry Standard*"
// ];



const parcel_options = [
    { value: 'Single', label: 'Single', name: "Parcel Type" },
    { value: 'Pair', label: 'Pair', name: "Parcel Type" },
    { value: 'Lot', label: 'Lot', name: "Parcel Type" },
    { value: 'Set', label: 'Set', name: "Parcel Type" }
];
const gems_cut = [
    { label: "Fine Step-cut", value: "Fine Step-cut", name: "Cut" },
    { label: "Modified Brilliant-cut", value: "Modified Brilliant-cut", name: "Cut" },
    { label: "Emerald-cut", value: "Emerald-cut", name: "Cut" },
    { label: "Radiant-cut", value: "Radiant-cut", name: "Cut" },
    { label: "Scissor-cut", value: "Scissor-cut", name: "Cut" },
    { label: "Brilliant Diamond-cut", value: "Brilliant Diamond-cut", name: "Cut" },
    { label: "Asscher-cut", value: "Asscher-cut", name: "Cut" },
    { label: "Princess-cut", value: "Princess-cut", name: "Cut" },
    { label: "Cabochon", value: "Cabochon", name: "Cut" },
    { label: "Rose-cut", value: "Rose-cut", name: "Cut" },
    { label: "Checkered-cut", value: "Checkered-cut", name: "Cut" },
    { label: "Thai-cut", value: "Thai-cut", name: "Cut" },
    { label: "Diamond-cut", value: "Diamond-cut", name: "Cut" },
];

const Shape = [
    { label: "Oval", value: "Oval", name: "Shape" },
    { label: "Pear", value: "Pear", name: "Shape" },
    { label: "Cushion", value: "Cushion", name: "Shape" },
    { label: "Rectangular", value: "Rectangular", name: "Shape" },
    { label: "Trilliant", value: "Trilliant", name: "Shape" },
    { label: "Heart", value: "Heart", name: "Shape" },
    { label: "Marquise", value: "Marquise", name: "Shape" },
    { label: "Round", value: "Round", name: "Shape" },
    { label: "Square", value: "Square", name: "Shape" },
    { label: "Fancy", value: "Fancy", name: "Shape" },
];

const trade_name = [
    { label: "Pigeon Blood", value: "Pigeon Blood", name: "Trade Name" },
    { label: "Vivid", value: "Vivid", name: "Trade Name" },
    { label: "Vibrant", value: "Vibrant", name: "Trade Name" },
    { label: "Royal Blue", value: "Royal Blue", name: "Trade Name" },
    { label: "Cornflower Blue", value: "Cornflower Blue", name: "Trade Name" },
    { label: "Santa Maria", value: "Santa Maria", name: "Trade Name" },
    { label: "Cats Eye", value: "Cats Eye", name: "Trade Name" },
    { label: "Star", value: "Star", name: "Trade Name" },
    { label: "Indicolite", value: "Indicolite", name: "Trade Name" },
    { label: "Mandarin", value: "Mandarin", name: "Trade Name" },
    { label: "Neon", value: "Neon", name: "Trade Name" },
    { label: "Umbalite", value: "Umbalite", name: "Trade Name" },
    { label: "Cambolite", value: "Cambolite", name: "Trade Name" },
    { label: "Watermelon", value: "Watermelon", name: "Trade Name" },
    { label: "Color Change", value: "Color Change", name: "Trade Name" },

]


const lab_id = [
    { label: "Gubelin", value: "Gubelin", name: "Lab" },
    { label: "SSEF", value: "SSEF", name: "Lab" },
    { label: "GRS", value: "GRS", name: "Lab" },
    { label: "GIA", value: "GIA", name: "Lab" },
    { label: "AGL - USA", value: "AGL - USA", name: "Lab" },
    { label: "AIGS", value: "AIGS", name: "Lab" },
    { label: "GIT", value: "GIT", name: "Lab" },
    { label: "GUILD", value: "GUILD", name: "Lab" },
    { label: "LOTUS", value: "LOTUS", name: "Lab" },
    { label: "ICA GemLab", value: "ICA GemLab", name: "Lab" },
    { label: "AGR", value: "AGR", name: "Lab" },
    { label: "AIG", value: "AIG", name: "Lab" },
    { label: "GIL", value: "GIL", name: "Lab" },
    { label: "GFCO", value: "GFCO", name: "Lab" },
    { label: "Bellerophon", value: "Bellerophon", name: "Lab" },


]


export {
    main_colors,
    sub_colors,
    treatments,
    parcel_options,
    gems_cut,
    Shape,
    trade_name,
    lab_id
    
}
