const countries = [
	{ label:"Afghanistan" ,value: "Afghanistan", name: "Origin Country",},
	{ label:"Albania" ,value: "Albania", name: "Origin Country",},
	{ label:"Algeria" ,value: "Algeria", name: "Origin Country",},
	{ label:"American Samoa" ,value: "American Samoa", name: "Origin Country",},
	{ label:"Andorra" ,value: "Andorra", name: "Origin Country",},
	{ label:"Angola" ,value: "Angola", name: "Origin Country",},
	{ label:"Anguilla" ,value: "Anguilla", name: "Origin Country",},
	{ label:"Antarctica" ,value: "Antarctica", name: "Origin Country",},
	{ label:"Antigua and Barbuda" ,value: "Antigua and Barbuda", name: "Origin Country",},
	{ label:"Argentina" ,value: "Argentina", name: "Origin Country",},
	{ label:"Armenia" ,value: "Armenia", name: "Origin Country",},
	{ label:"Aruba" ,value: "Aruba", name: "Origin Country",},
	{ label:"Australia" ,value: "Australia", name: "Origin Country",},
	{ label:"Austria" ,value: "Austria", name: "Origin Country",},
	{ label:"Azerbaijan" ,value: "Azerbaijan", name: "Origin Country",},
	{ label:"Bahamas (the)" ,value: "Bahamas (the)", name: "Origin Country",},
	{ label:"Bahrain" ,value: "Bahrain", name: "Origin Country",},
	{ label:"Bangladesh" ,value: "Bangladesh", name: "Origin Country",},
	{ label:"Barbados" ,value: "Barbados", name: "Origin Country",},
	{ label:"Belarus" ,value: "Belarus", name: "Origin Country",},
	{ label:"Belgium" ,value: "Belgium", name: "Origin Country",},
	{ label:"Belize" ,value: "Belize", name: "Origin Country",},
	{ label:"Benin" ,value: "Benin", name: "Origin Country",},
	{ label:"Bermuda" ,value: "Bermuda", name: "Origin Country",},
	{ label:"Bhutan" ,value: "Bhutan", name: "Origin Country",},
	{ label:"Bolivia (Plurinational State of)" ,value: "Bolivia (Plurinational State of)", name: "Origin Country",},
	{ label:"Bonaire, Sint Eustatius and Saba" ,value: "Bonaire, Sint Eustatius and Saba", name: "Origin Country",},
	{ label:"Bosnia and Herzegovina" ,value: "Bosnia and Herzegovina", name: "Origin Country",},
	{ label:"Botswana" ,value: "Botswana", name: "Origin Country",},
	{ label:"Bouvet Island" ,value: "Bouvet Island", name: "Origin Country",},
	{ label:"Brazil" ,value: "Brazil", name: "Origin Country",},
	{ label:"British Indian Ocean Territory (the)" ,value: "British Indian Ocean Territory (the)", name: "Origin Country",},
	{ label:"Brunei Darussalam" ,value: "Brunei Darussalam", name: "Origin Country",},
	{ label:"Bulgaria" ,value: "Bulgaria", name: "Origin Country",},
	{ label:"Burkina Faso" ,value: "Burkina Faso", name: "Origin Country",},
	{ label:"Burundi" ,value: "Burundi", name: "Origin Country",},
	{ label:"Cabo Verde" ,value: "Cabo Verde", name: "Origin Country",},
	{ label:"Cambodia" ,value: "Cambodia", name: "Origin Country",},
	{ label:"Cameroon" ,value: "Cameroon", name: "Origin Country",},
	{ label:"Canada" ,value: "Canada", name: "Origin Country",},
	{ label:"Cayman Islands (the)" ,value: "Cayman Islands (the)", name: "Origin Country",},
	{ label:"Central African Republic (the)" ,value: "Central African Republic (the)", name: "Origin Country",},
	{ label:"Chad" ,value: "Chad", name: "Origin Country",},
	{ label:"Chile" ,value: "Chile", name: "Origin Country",},
	{ label:"China" ,value: "China", name: "Origin Country",},
	{ label:"Christmas Island" ,value: "Christmas Island", name: "Origin Country",},
	{ label:"Cocos (Keeling) Islands (the)" ,value: "Cocos (Keeling) Islands (the)", name: "Origin Country",},
	{ label:"Colombia" ,value: "Colombia", name: "Origin Country",},
	{ label:"Comoros (the)" ,value: "Comoros (the)", name: "Origin Country",},
	{ label:"Congo (the Democratic Republic of the)" ,value: "Congo (the Democratic Republic of the)", name: "Origin Country",},
	{ label:"Congo (the)" ,value: "Congo (the)", name: "Origin Country",},
	{ label:"Cook Islands (the)" ,value: "Cook Islands (the)", name: "Origin Country",},
	{ label:"Costa Rica" ,value: "Costa Rica", name: "Origin Country",},
	{ label:"Croatia" ,value: "Croatia", name: "Origin Country",},
	{ label:"Cuba" ,value: "Cuba", name: "Origin Country",},
	{ label:"Curaçao" ,value: "Curaçao", name: "Origin Country",},
	{ label:"Cyprus" ,value: "Cyprus", name: "Origin Country",},
	{ label:"Czechia" ,value: "Czechia", name: "Origin Country",},
	{ label:"Côte d'Ivoire" ,value: "Côte d'Ivoire", name: "Origin Country",},
	{ label:"Denmark" ,value: "Denmark", name: "Origin Country",},
	{ label:"Djibouti" ,value: "Djibouti", name: "Origin Country",},
	{ label:"Dominica" ,value: "Dominica", name: "Origin Country",},
	{ label:"Dominican Republic (the)" ,value: "Dominican Republic (the)", name: "Origin Country",},
	{ label:"Ecuador" ,value: "Ecuador", name: "Origin Country",},
	{ label:"Egypt" ,value: "Egypt", name: "Origin Country",},
	{ label:"El Salvador" ,value: "El Salvador", name: "Origin Country",},
	{ label:"Equatorial Guinea" ,value: "Equatorial Guinea", name: "Origin Country",},
	{ label:"Eritrea" ,value: "Eritrea", name: "Origin Country",},
	{ label:"Estonia" ,value: "Estonia", name: "Origin Country",},
	{ label:"Eswatini" ,value: "Eswatini", name: "Origin Country",},
	{ label:"Ethiopia" ,value: "Ethiopia", name: "Origin Country",},
	{ label:"Falkland Islands (the) [Malvinas]" ,value: "Falkland Islands (the) [Malvinas]", name: "Origin Country",},
	{ label:"Faroe Islands (the)" ,value: "Faroe Islands (the)", name: "Origin Country",},
	{ label:"Fiji" ,value: "Fiji", name: "Origin Country",},
	{ label:"Finland" ,value: "Finland", name: "Origin Country",},
	{ label:"France" ,value: "France", name: "Origin Country",},
	{ label:"French Guiana" ,value: "French Guiana", name: "Origin Country",},
	{ label:"French Polynesia" ,value: "French Polynesia", name: "Origin Country",},
	{ label:"French Southern Territories (the)" ,value: "French Southern Territories (the)", name: "Origin Country",},
	{ label:"Gabon" ,value: "Gabon", name: "Origin Country",},
	{ label:"Gambia (the)" ,value: "Gambia (the)", name: "Origin Country",},
	{ label:"Georgia" ,value: "Georgia", name: "Origin Country",},
	{ label:"Germany" ,value: "Germany", name: "Origin Country",},
	{ label:"Ghana" ,value: "Ghana", name: "Origin Country",},
	{ label:"Gibraltar" ,value: "Gibraltar", name: "Origin Country",},
	{ label:"Greece" ,value: "Greece", name: "Origin Country",},
	{ label:"Greenland" ,value: "Greenland", name: "Origin Country",},
	{ label:"Grenada" ,value: "Grenada", name: "Origin Country",},
	{ label:"Guadeloupe" ,value: "Guadeloupe", name: "Origin Country",},
	{ label:"Guam" ,value: "Guam", name: "Origin Country",},
	{ label:"Guatemala" ,value: "Guatemala", name: "Origin Country",},
	{ label:"Guernsey" ,value: "Guernsey", name: "Origin Country",},
	{ label:"Guinea" ,value: "Guinea", name: "Origin Country",},
	{ label:"Guinea-Bissau" ,value: "Guinea-Bissau", name: "Origin Country",},
	{ label:"Guyana" ,value: "Guyana", name: "Origin Country",},
	{ label:"Haiti" ,value: "Haiti", name: "Origin Country",},
	{ label:"Heard Island and McDonald Islands" ,value: "Heard Island and McDonald Islands", name: "Origin Country",},
	{ label:"Holy See (the)" ,value: "Holy See (the)", name: "Origin Country",},
	{ label:"Honduras" ,value: "Honduras", name: "Origin Country",},
	{ label:"Hong Kong" ,value: "Hong Kong", name: "Origin Country",},
	{ label:"Hungary" ,value: "Hungary", name: "Origin Country",},
	{ label:"Iceland" ,value: "Iceland", name: "Origin Country",},
	{ label:"India" ,value: "India", name: "Origin Country",},
	{ label:"Indonesia" ,value: "Indonesia", name: "Origin Country",},
	{ label:"Iran (Islamic Republic of)" ,value: "Iran (Islamic Republic of)", name: "Origin Country",},
	{ label:"Iraq" ,value: "Iraq", name: "Origin Country",},
	{ label:"Ireland" ,value: "Ireland", name: "Origin Country",},
	{ label:"Isle of Man" ,value: "Isle of Man", name: "Origin Country",},
	{ label:"Israel" ,value: "Israel", name: "Origin Country",},
	{ label:"Italy" ,value: "Italy", name: "Origin Country",},
	{ label:"Jamaica" ,value: "Jamaica", name: "Origin Country",},
	{ label:"Japan" ,value: "Japan", name: "Origin Country",},
	{ label:"Jersey" ,value: "Jersey", name: "Origin Country",},
	{ label:"Jordan" ,value: "Jordan", name: "Origin Country",},
	{ label:"Kazakhstan" ,value: "Kazakhstan", name: "Origin Country",},
	{ label:"Kenya" ,value: "Kenya", name: "Origin Country",},
	{ label:"Kiribati" ,value: "Kiribati", name: "Origin Country",},
	{ label:"Korea (the Democratic People's Republic of)" ,value: "Korea (the Democratic People's Republic of)", name: "Origin Country",},
	{ label:"Korea (the Republic of)" ,value: "Korea (the Republic of)", name: "Origin Country",},
	{ label:"Kuwait" ,value: "Kuwait", name: "Origin Country",},
	{ label:"Kyrgyzstan" ,value: "Kyrgyzstan", name: "Origin Country",},
	{ label:"Lao People's Democratic Republic (the)" ,value: "Lao People's Democratic Republic (the)", name: "Origin Country",},
	{ label:"Latvia" ,value: "Latvia", name: "Origin Country",},
	{ label:"Lebanon" ,value: "Lebanon", name: "Origin Country",},
	{ label:"Lesotho" ,value: "Lesotho", name: "Origin Country",},
	{ label:"Liberia" ,value: "Liberia", name: "Origin Country",},
	{ label:"Libya" ,value: "Libya", name: "Origin Country",},
	{ label:"Liechtenstein" ,value: "Liechtenstein", name: "Origin Country",},
	{ label:"Lithuania" ,value: "Lithuania", name: "Origin Country",},
	{ label:"Luxembourg" ,value: "Luxembourg", name: "Origin Country",},
	{ label:"Macao" ,value: "Macao", name: "Origin Country",},
	{ label:"Madagascar" ,value: "Madagascar", name: "Origin Country",},
	{ label:"Malawi" ,value: "Malawi", name: "Origin Country",},
	{ label:"Malaysia" ,value: "Malaysia", name: "Origin Country",},
	{ label:"Maldives" ,value: "Maldives", name: "Origin Country",},
	{ label:"Mali" ,value: "Mali", name: "Origin Country",},
	{ label:"Malta" ,value: "Malta", name: "Origin Country",},
	{ label:"Marshall Islands (the)" ,value: "Marshall Islands (the)", name: "Origin Country",},
	{ label:"Martinique" ,value: "Martinique", name: "Origin Country",},
	{ label:"Mauritania" ,value: "Mauritania", name: "Origin Country",},
	{ label:"Mauritius" ,value: "Mauritius", name: "Origin Country",},
	{ label:"Mayotte" ,value: "Mayotte", name: "Origin Country",},
	{ label:"Mexico" ,value: "Mexico", name: "Origin Country",},
	{ label:"Micronesia (Federated States of)" ,value: "Micronesia (Federated States of)", name: "Origin Country",},
	{ label:"Moldova (the Republic of)" ,value: "Moldova (the Republic of)", name: "Origin Country",},
	{ label:"Monaco" ,value: "Monaco", name: "Origin Country",},
	{ label:"Mongolia" ,value: "Mongolia", name: "Origin Country",},
	{ label:"Montenegro" ,value: "Montenegro", name: "Origin Country",},
	{ label:"Montserrat" ,value: "Montserrat", name: "Origin Country",},
	{ label:"Morocco" ,value: "Morocco", name: "Origin Country",},
	{ label:"Mozambique" ,value: "Mozambique", name: "Origin Country",},
	{ label:"Myanmar" ,value: "Myanmar", name: "Origin Country",},
	{ label:"Namibia" ,value: "Namibia", name: "Origin Country",},
	{ label:"Nauru" ,value: "Nauru", name: "Origin Country",},
	{ label:"Nepal" ,value: "Nepal", name: "Origin Country",},
	{ label:"Netherlands (the)" ,value: "Netherlands (the)", name: "Origin Country",},
	{ label:"New Caledonia" ,value: "New Caledonia", name: "Origin Country",},
	{ label:"New Zealand" ,value: "New Zealand", name: "Origin Country",},
	{ label:"Nicaragua" ,value: "Nicaragua", name: "Origin Country",},
	{ label:"Niger (the)" ,value: "Niger (the)", name: "Origin Country",},
	{ label:"Nigeria" ,value: "Nigeria", name: "Origin Country",},
	{ label:"Niue" ,value: "Niue", name: "Origin Country",},
	{ label:"Norfolk Island" ,value: "Norfolk Island", name: "Origin Country",},
	{ label:"Northern Mariana Islands (the)" ,value: "Northern Mariana Islands (the)", name: "Origin Country",},
	{ label:"Norway" ,value: "Norway", name: "Origin Country",},
	{ label:"Oman" ,value: "Oman", name: "Origin Country",},
	{ label:"Pakistan" ,value: "Pakistan", name: "Origin Country",},
	{ label:"Palau" ,value: "Palau", name: "Origin Country",},
	{ label:"Palestine, State of" ,value: "Palestine, State of", name: "Origin Country",},
	{ label:"Panama" ,value: "Panama", name: "Origin Country",},
	{ label:"Papua New Guinea" ,value: "Papua New Guinea", name: "Origin Country",},
	{ label:"Paraguay" ,value: "Paraguay", name: "Origin Country",},
	{ label:"Peru" ,value: "Peru", name: "Origin Country",},
	{ label:"Philippines (the)" ,value: "Philippines (the)", name: "Origin Country",},
	{ label:"Pitcairn" ,value: "Pitcairn", name: "Origin Country",},
	{ label:"Poland" ,value: "Poland", name: "Origin Country",},
	{ label:"Portugal" ,value: "Portugal", name: "Origin Country",},
	{ label:"Puerto Rico" ,value: "Puerto Rico", name: "Origin Country",},
	{ label:"Qatar" ,value: "Qatar", name: "Origin Country",},
	{ label:"Republic of North Macedonia" ,value: "Republic of North Macedonia", name: "Origin Country",},
	{ label:"Romania" ,value: "Romania", name: "Origin Country",},
	{ label:"Russian Federation (the)" ,value: "Russian Federation (the)", name: "Origin Country",},
	{ label:"Rwanda" ,value: "Rwanda", name: "Origin Country",},
	{ label:"Réunion" ,value: "Réunion", name: "Origin Country",},
	{ label:"Saint Barthélemy" ,value: "Saint Barthélemy", name: "Origin Country",},
	{ label:"Saint Helena, Ascension and Tristan da Cunha" ,value: "Saint Helena, Ascension and Tristan da Cunha", name: "Origin Country",},
	{ label:"Saint Kitts and Nevis" ,value: "Saint Kitts and Nevis", name: "Origin Country",},
	{ label:"Saint Lucia" ,value: "Saint Lucia", name: "Origin Country",},
	{ label:"Saint Martin (French part)" ,value: "Saint Martin (French part)", name: "Origin Country",},
	{ label:"Saint Pierre and Miquelon" ,value: "Saint Pierre and Miquelon", name: "Origin Country",},
	{ label:"Saint Vincent and the Grenadines" ,value: "Saint Vincent and the Grenadines", name: "Origin Country",},
	{ label:"Samoa" ,value: "Samoa", name: "Origin Country",},
	{ label:"San Marino" ,value: "San Marino", name: "Origin Country",},
	{ label:"Sao Tome and Principe" ,value: "Sao Tome and Principe", name: "Origin Country",},
	{ label:"Saudi Arabia" ,value: "Saudi Arabia", name: "Origin Country",},
	{ label:"Senegal" ,value: "Senegal", name: "Origin Country",},
	{ label:"Serbia" ,value: "Serbia", name: "Origin Country",},
	{ label:"Seychelles" ,value: "Seychelles", name: "Origin Country",},
	{ label:"Sierra Leone" ,value: "Sierra Leone", name: "Origin Country",},
	{ label:"Singapore" ,value: "Singapore", name: "Origin Country",},
	{ label:"Sint Maarten (Dutch part)" ,value: "Sint Maarten (Dutch part)", name: "Origin Country",},
	{ label:"Slovakia" ,value: "Slovakia", name: "Origin Country",},
	{ label:"Slovenia" ,value: "Slovenia", name: "Origin Country",},
	{ label:"Solomon Islands" ,value: "Solomon Islands", name: "Origin Country",},
	{ label:"Somalia" ,value: "Somalia", name: "Origin Country",},
	{ label:"South Africa" ,value: "South Africa", name: "Origin Country",},
	{ label:"South Georgia and the South Sandwich Islands" ,value: "South Georgia and the South Sandwich Islands", name: "Origin Country",},
	{ label:"South Sudan" ,value: "South Sudan", name: "Origin Country",},
	{ label:"Spain" ,value: "Spain", name: "Origin Country",},
	{ label:"Sri Lanka" ,value: "Sri Lanka", name: "Origin Country",},
	{ label:"Sudan (the)" ,value: "Sudan (the)", name: "Origin Country",},
	{ label:"Suriname" ,value: "Suriname", name: "Origin Country",},
	{ label:"Svalbard and Jan Mayen" ,value: "Svalbard and Jan Mayen", name: "Origin Country",},
	{ label:"Sweden" ,value: "Sweden", name: "Origin Country",},
	{ label:"Switzerland" ,value: "Switzerland", name: "Origin Country",},
	{ label:"Syrian Arab Republic" ,value: "Syrian Arab Republic", name: "Origin Country",},
	{ label:"Taiwan" ,value: "Taiwan", name: "Origin Country",},
	{ label:"Tajikistan" ,value: "Tajikistan", name: "Origin Country",},
	{ label:"Tanzania, United Republic of" ,value: "Tanzania, United Republic of", name: "Origin Country",},
	{ label:"Thailand" ,value: "Thailand", name: "Origin Country",},
	{ label:"Timor-Leste" ,value: "Timor-Leste", name: "Origin Country",},
	{ label:"Togo" ,value: "Togo", name: "Origin Country",},
	{ label:"Tokelau" ,value: "Tokelau", name: "Origin Country",},
	{ label:"Tonga" ,value: "Tonga", name: "Origin Country",},
	{ label:"Trinidad and Tobago" ,value: "Trinidad and Tobago", name: "Origin Country",},
	{ label:"Tunisia" ,value: "Tunisia", name: "Origin Country",},
	{ label:"Turkey" ,value: "Turkey", name: "Origin Country",},
	{ label:"Turkmenistan" ,value: "Turkmenistan", name: "Origin Country",},
	{ label:"Turks and Caicos Islands (the)" ,value: "Turks and Caicos Islands (the)", name: "Origin Country",},
	{ label:"Tuvalu" ,value: "Tuvalu", name: "Origin Country",},
	{ label:"Uganda" ,value: "Uganda", name: "Origin Country",},
	{ label:"Ukraine" ,value: "Ukraine", name: "Origin Country",},
	{ label:"United Arab Emirates (the)" ,value: "United Arab Emirates (the)", name: "Origin Country",},
	{ label:"United Kingdom of Great Britain and Northern Ireland (the)" ,value: "United Kingdom of Great Britain and Northern Ireland (the)", name: "Origin Country",},
	{ label:"United States Minor Outlying Islands (the)" ,value: "United States Minor Outlying Islands (the)", name: "Origin Country",},
	{ label:"United States of America (the)" ,value: "United States of America (the)", name: "Origin Country",},
	{ label:"Uruguay" ,value: "Uruguay", name: "Origin Country",},
	{ label:"Uzbekistan" ,value: "Uzbekistan", name: "Origin Country",},
	{ label:"Vanuatu" ,value: "Vanuatu", name: "Origin Country",},
	{ label:"Venezuela (Bolivarian Republic of)" ,value: "Venezuela (Bolivarian Republic of)", name: "Origin Country",},
	{ label:"Viet Nam" ,value: "Viet Nam", name: "Origin Country",},
	{ label:"Virgin Islands (British)" ,value: "Virgin Islands (British)", name: "Origin Country",},
	{ label:"Virgin Islands (U.S.)" ,value: "Virgin Islands (U.S.)", name: "Origin Country",},
	{ label:"Wallis and Futuna" ,value: "Wallis and Futuna", name: "Origin Country",},
	{ label:"Western Sahara" ,value: "Western Sahara", name: "Origin Country",},
	{ label:"Yemen" ,value: "Yemen", name: "Origin Country",},
	{ label:"Zambia" ,value: "Zambia", name: "Origin Country",},
	{ label:"Zimbabwe" ,value: "Zimbabwe", name: "Origin Country",},
	{ label:"Åland Islands" ,value: "Åland Islands", name: "Origin Country",},
];
export default countries