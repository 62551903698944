// Form will go here 
import React from 'react';
import Layout from '../commons/layout';
import axios from 'axios';
// import _ from 'lodash';
import { Link } from 'react-router-dom'
import Pagination from '../commons/pagination';

class ViewInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inventory: [],
            categories: [],
            subcategories: [],
            token: localStorage.getItem('userToken'),
            tableColumn: [
                { heading: "Name" },
                { heading: "Quantity" },
                { heading: "Weight" },
                { heading: "Category" },
                { heading: "Sell Price" },
                { heading: "Action" }
            ],
            page:1,
            totalPage: 1
        }
        this.getCategories();
        this.getInventory();
    }

    getCategories = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data;
            console.log(data);
            let category_data = {};
            data.map((item)=>{
                category_data[item._id] = item.name
                return 0
            });
            console.log(category_data);
            this.setState({
                categories: category_data,
            })
        }
        catch (e) {
            console.log(e);
        }
    }
    getInventory = async () => {
        try {
            let userId = localStorage.getItem('userId');
            console.log(userId);
            let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/inventory/${userId}/${this.state.page}`, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            let data = response.data.data.result;
            console.log(data);
            this.setState({
                inventory: data,
                totalPage: response.data.data.totalPages,
                // subcategoriesLoaded: false,
                // subcategories: [],
                isCatLoaded: true,
            })
        }
        catch (e) {
            console.log(e);
        }
    }

    pageChange = (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        this.setState({
            page: Number(p)
        },()=>{
            this.getInventory();
        });

    };

    getCategoryName = async (id) => {
        return this.state.categories.find((element) => {
            return (!!(element._id === id)) ? element.name : "";
        })
    }

    deleteInventory = async (e) => {
        if (window.confirm('Are You Sure?')) {
            let id = e.target.getAttribute('data-inv_id');
            console.log(id);
            // alert('Thanks for confirming');

            let inv = this.state.inventory.filter(function (el) {
                return el._id !== id;
            });
            let response = await axios.delete('https://caratpal.thesecondwheel.in/api/inventory/'+id, { headers: { "Authorization": `Bearer ${this.state.token}` } });
            console.log(response);
            if(response.data.status === "Success"){
                console.log(inv);
                this.setState({
                    inventory: inv
                })
            }
        } else {
            // alert('Why did you press cancel? You should have confirmed');
        }

    }

    render() {
        return <div>
            <Layout />
            <main>

                {/* Basic Details  */}
                <div className="card w-100 ">
                    <div className="card-body pb-0">
                        <h5 className="card-title"> View Inventory Details </h5>
                        {/* <div>
                {this.state.inventory.map(item =>
                    <div key={item._id} value={item._id}> <a href={"/inventory/"+item._id}>{item.name}</a> </div>
                    )}
                </div> */}
                    </div>
                    <table className='m-3'>
                        <thead>
                            <tr>
                                {this.state.tableColumn.map((item, idx) =>
                                    <th key={idx}>{item.heading} </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.inventory.map((item, idx) =>
                                <tr key={idx}>
                                    <td><Link to={"/inventory/" + item._id}>{item.name}</Link></td>
                                    <td>{item.count}</td>
                                    <td>{item.weight}</td>
                                    <td>{ this.state.categories[item.categoryId[0]]}</td>
                                    <td>{item.sell_price}</td>
                                    <td><i onClick={this.deleteInventory} data-inv_id={item._id} class="btn btn-sm bi bi-trash text-danger"></i></td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={6}>
                                    <div className='mt-2 pagination justify-content-center'>
                                        {/* <div className='page-item'> */}
                                        <Pagination
                                            page={this.state.page}
                                            pageChange={this.pageChange}
                                            totalPage={this.state.totalPage}
                                        />
                                        {/* </div> */}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                {/* </div> */}

            </main>
        </div>
    }
}

export default ViewInventory; 