/**
 * Login feature to be implemented here use axios for making http requests  
 * https://axios-http.com/
 */
import axios from 'axios';
import React from 'react';
import navigationHook from '../commons/hooks';

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileNumber: '',
        }
    }

    componentDidMount() {
        this.checkLogin()
    }
    checkLogin = () =>{ 
        if (localStorage.getItem('userToken')) {
            this.props.navigate('/add');
            // return;
        }
    }
    login = async () => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/user/login`,
            data: {
                mobile: this.state.mobileNumber,
                otp: "1234"
            }
        }).then((response) => {
            console.log(response.data);
            if (response.data.data.jwt) {
                localStorage.setItem('userToken', response.data.data.jwt);
                localStorage.setItem('userId', response.data.data._id);
                // console.log(localStorage.getItem('userToken'));
                // console.log(localStorage.getItem('userId'));
                // alert("Logged in"); 
                // window.location = '/add';
                this.props.navigate('/add');
            }
        }).catch((e) => {
            console.log(e);
            alert("Failed to log in", e);
        })
    }
    handleInput = async (e) => {
        this.setState({
            mobileNumber: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    render() {
        return <>
            {/* <Layout /> */}
            <div className="container">

                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center ">
                                    <a href="/" className="logo d-flex align-items-center w-auto">
                                        {/* <img src="assets/img/logo.png" alt=""> */}
                                        {/* <span className="d-none d-lg-block">CaratPal</span> */}
                                    </a>
                                </div>
                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                        </div>

                                        <div className="row g-3">

                                            <div className="col-12">
                                                <label htmlFor="mobile_no" className="form-label"> Mobile Number </label>
                                                <div className="input-group has-validation">
                                                    <input type="tel" name="mobile_no" onKeyUp={this.handleInput} className="form-control" id="mobile_no" required />
                                                    <div className="invalid-feedback">Please enter valid mobile number </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" onClick={this.login} type="submit">Login</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    }
}

export default navigationHook(Login); 
